
// -----------------------------------------------------------
// DOCUMENT READY
// -----------------------------------------------------------
//
import '../scss/main.scss';

jQuery(function() {
    Menu = new Menu();
    Anchors = new Anchors();
    FadeIn = new FadeIn();
    Portfolio = new Portfolio();
    $('body').addClass('js');
});
//-----------------------------------------------------------
// FADEIN
// -----------------------------------------------------------

var FadeIn = function() {
    this.selector = ".js-fadein, .js-fadein-up, .js-fadein-down, .js-fadein-left, .js-fadein-right",
        this.body = $(document.body),
        this.window = $(window),
        this.resizeBound = this.resize.bind(this),
        this.scrollBound = this.scroll.bind(this),
        this.clear(),
        this.parse()
}

FadeIn.prototype.clear = function () {
    this.window.off("resize", this.resizeBound), this.window.off("scroll", this.scrollBound), this.items = [], delete this.item
}
FadeIn.prototype.parse = function (t) {
    t || (t = this.body);
    var i = t.filter(this.selector).add(t.find(this.selector));
    if (i.removeClass("show"), i.length) {
        var e;
        i.each(function (t, i) {
            i = $(i), e = i.hasClass("js-fadein-prio"), this.items.push({
                element: i,
                hold: parseInt(i.attr("data-fadein-hold") || 0),
                prio: e,
                prioSort: e ? 1 : 2,
                top: Number.MAX_VALUE
            })
        }.bind(this)), this.item || (this.window.on("resize", this.resizeBound), this.window.on("scroll", this.scrollBound)), $("img").on("load", this.resizeBound), this.resize()
    }
}

FadeIn.prototype.set = function (t, i, e) {
    var o = '[class*="js-fadein"]',
        n = t.filter(o);
    e && (n = n.add(t.find(o))), n.toggleClass("show", i)
}

FadeIn.prototype.reverse = function (t, i) {
    var e = t.filter(this.selector).add(t.find(this.selector));
    e.addClass("fadeout"), $.isFunction(i) && setTimeout(function () {
        i()
    }, 800)
}

FadeIn.prototype.resize = function (t) {
    clearTimeout(this.resizeTimeout), this.resizeTimeout = setTimeout(function () {
        this.windowHeight = this.window.height(), this.documentHeight = $body.outerHeight() - .3 * this.windowHeight;
        var t, i;
        for (t = 0; t < this.items.length; t++) i = this.items[t], i.top = i.element.offset().top;
        this.items.sortOn("prioSort", "top"), this.item = this.items[0], this.scroll()
    }.bind(this), t ? 100 : 0)
}

FadeIn.prototype.scroll = function (t) {
    if (t || (clearTimeout(this.timeout), delete this.timeout), this.item && !this.timeout) {
        var i = this.window.scrollTop(),
            e = i + this.windowHeight * (t ? 0.8 : 1);
        if (WIDESCREEN || this.item.top <= e || i + this.windowHeight >= this.documentHeight) {
            // Add a small delay to allow the initial state to render
            setTimeout(() => {
                this.item.element.addClass("show"); // Add the 'show' class after a delay
            }, 10); // 10ms delay

            this.items.shift();
            var o = this.items[0];
            if (o) {
                var n = this.item.top < i || this.item.top === o.top ? 0 : 200 + this.item.hold;
                this.item = o;
                this.timeout = setTimeout(this.scrollBound, n);
            } else {
                this.clear();
            }
        }
    }
}

// -----------------------------------------------------------
// WaitTimes
// -----------------------------------------------------------
var WaitTimes = function() {
    this.element = jQuery('.is-waittime');
    if (!this.element.length) return;
    var waitTimer;
    this.element.each(function(index, item) {
        var items = jQuery(item);
        var waitTimer = new WaitTimer(items);
    });
};


var WaitTimer = function(items) {
    this.element = items;
    this.item = this.element.find('.is-waittime__time');
    this.days = this.item.attr('data-days');
    jQuery(window).scrollTop();
    this.parse()
    jQuery(window).scroll(this.parse.bind(this));
  }

WaitTimer.prototype.parse = function() {
  jQuery(window).scrollTop();
   var top_of_element = this.element.offset().top;
   var bottom_of_element = this.element.offset().top + this.element.outerHeight();
   var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight() - 20;
   var top_of_screen = $(window).scrollTop();
   if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
       this.calculate();
     }
};

WaitTimer.prototype.calculate = function() {
    this.width = ((100 / 365) * this.days)  ;
    this.item.css("width", `${this.width}%`)
};


// // -----------------------------------------------------------
// // LOAD MORE
// // -----------------------------------------------------------
// var LoadMore = function() {
//     this.element = jQuery('.js-LoadMore');
//     if (!this.element.length) return;
//     var loadMoreElements;
//     this.element.each(function(index, item) {
//         var items = jQuery(item);
//         var loadMoreElements = new LoadMoreElements(items);
//     });
// };
// var LoadMoreElements = function(items) {
//     this.element = items;
//     this.items = this.element.find('.js-item');
//     this.text = this.element.attr('data-text');
//     if (this.items.length > 7) {
//         this.element.append(`
//                 <div class="section">
//                     <p class="is-primary is-size-4 is-bold js-btn" >
//                         <span>${this.text}</span>
//                     </p>
//                 </div>
//             `);
//         this.items.hide();
//         this.items.slice(0, 8).show();
//     }
//     this.items.hide();
//     this.items.slice(0, 7).show();
//     this.btn = this.element.find('.js-btn');
//     this.btn.on('click', this.more.bind(this));
// }
// LoadMoreElements.prototype.more = function(e) {
//
//     this.items.filter(':hidden').slice(0, 8).show();
//     if (this.items.length == this.items.filter(':visible').length) {
//         this.btn.hide();
//     }
// };

// -----------------------------------------------------------
// MENU
// -----------------------------------------------------------
var Menu = function() {
    this.button = jQuery('.js-menu-button');
    this.body = jQuery(document.body),
    jQuery(window).resize(this.height.bind(this));
    this.height();
    jQuery(window).scrollTop();
    jQuery(window).scroll(this.scrolled.bind(this));
    this.button.on('click', this.menutoggle.bind(this));
}
Menu.prototype.menutoggle = function() {
    event.preventDefault();
    if (!this.body.hasClass('menu-open')) {
        this.body.addClass('menu-open');
    } else {
        this.body.removeClass('menu-open');
    }
}
Menu.prototype.scrolled = function() {
    var scroll = jQuery(window).scrollTop();
    if (scroll >= 1) {
        if (jQuery('.is-scrolled').length) return;
        jQuery('body').addClass("is-scrolled");
    } else if (scroll <= 1) {
        if (jQuery('.is-scrolled').length) {
            jQuery('body').removeClass("is-scrolled");
        }
    }
}
Menu.prototype.height = function() {
    this.menu = jQuery('.navbar-end').outerHeight() ;

    var mq = window.matchMedia( "(max-width: 1239px)" );
    if (mq.matches) {
        // window width is at less than 570px
    }
    else {
      this.menu = this.menu * 1.75;
      jQuery('.header-section').css({
          "margin-top": `-${this.menu}px`
      });
        jQuery('header').css({
          "margin-bottom": `- ${this.menu}px`
      });
    }


}

// -----------------------------------------------------------
// ANCHORS
// -----------------------------------------------------------
var Anchors = function() {
	this.items = [];
	this.parse();
	$window.on('hashchange', this.hashchange.bind(this));
}

Anchors.prototype.parse = function() {
	var anchor;
	jQuery('.js-anchor').each(function(index, item) {
		item = jQuery(item);
		if (!item.data('anchor')) {
			anchor = new Anchor(item);
			item.data('anchor', anchor);
			this.items.push(anchor);
		}
	}.bind(this));

	this.hashchange();
}

Anchors.prototype.hashchange = function(e) {
	var hash = window.location.hash.replace('#', '');
	if (this.hash != hash || !e) {
		this.hash = hash;
		if (hash) {
			var i, item;
			for (i=0; i<this.items.length; i++) {
				item = this.items[i];
				if (item.id == hash) {
					$bodyHtml.stop(true).animate({'scrollTop': item.target.offset().top - 72});
				}
			}
		}
	}
}

// -----------------------------------------------------------
// ANCHOR
// -----------------------------------------------------------
var Anchor = function(element) {
	this.element = element;
	if (!this.element.length) return;
	this.element.on('click', this.click.bind(this));

	this.id = this.element.attr('href').split('#').pop();

	this.target = jQuery('#' + this.id);
	this.target.attr('id', 'js-' + this.id);
}

Anchor.prototype.click = function(e) {
	if (this.id == Anchors.hash) {
		Anchors.hashchange();
	}
}

// -----------------------------------------------------------
// PORTFOLIO
// -----------------------------------------------------------
var Portfolio = function() {
	this.element = jQuery('.portfolio');
  this.btn = jQuery('.js-portfolio-btn');
  this.parse();
	if (!this.element.length) return;
	// this.btn.on('click', this.click.bind(this));


}

Portfolio.prototype.parse = function() {
  var portfolioBtn;
	this.btn.each(function(index, item) {
		item = jQuery(item);
    console.log(item);
		if (item) {
			portfolioBtn = new PortfolioBtn(item);
			// item.data('anchor', anchor);
			// this.items.push(anchor);
		}
	}.bind(this));
}

// PortfolioBtn.prototype.click = function(e) {
//   console.log(this.btn.closest('.portfolio-item'));
//   // // TODO: move porfolio item down.
//   // animate
//
//   this.btn.closest('.portfolio-item').hide();
//
// }


document.addEventListener('DOMContentLoaded', () => {
    const burger = document.querySelector('.navbar-burger');
    const menu = document.querySelector('.navbar-menu');
    const overlay = document.querySelector('.menu-overlay');
    const closeButton = document.querySelector('.close-button');
  
    // Open the menu
    burger.addEventListener('click', () => {
      menu.classList.add('is-active');
      overlay.classList.add('is-active');
    });
  
    // Close the menu
    const closeMenu = () => {
      menu.classList.remove('is-active');
      overlay.classList.remove('is-active');
    };
  
    closeButton.addEventListener('click', closeMenu);
  
    // Close menu when clicking the overlay
    overlay.addEventListener('click', closeMenu);
  });  

  document.addEventListener('DOMContentLoaded', () => {
    const detailsElements = document.querySelectorAll('.accordion details');
  
    detailsElements.forEach((details, index) => {
      if (index === 0) {
        details.setAttribute('open', '');
        animateOpen(details);
      } else {
        details.removeAttribute('open');
      }
  
      const summary = details.querySelector('summary');
      summary.addEventListener('click', (event) => {
        event.preventDefault();
  
        detailsElements.forEach((otherDetails) => {
          if (otherDetails !== details) {
            animateClose(otherDetails);
            otherDetails.removeAttribute('open');
          }
        });
  
        if (details.hasAttribute('open')) {
          animateClose(details);
          details.removeAttribute('open');
        } else {
          details.setAttribute('open', '');
          animateOpen(details);
        }
      });
    });
  
    function animateOpen(details) {
        const panelBody = details.querySelector('.panel-body');
        requestAnimationFrame(() => {
          panelBody.style.maxHeight = `${panelBody.scrollHeight}px`;
        });
      }
      
      function animateClose(details) {
        const panelBody = details.querySelector('.panel-body');
        panelBody.style.maxHeight = '0';
      }      
  });
   
  
  document.addEventListener("DOMContentLoaded", function () {
    const cards = document.querySelectorAll(".cards-wrapper .card");

    function adjustCardStyles() {
        cards.forEach((card) => {
            const columnNumber = card.querySelector(".column-number");
            const stroke = card.querySelector(".stroke");
            const cardContent = card.querySelector(".card-content");
            const cardParagraph = cardContent.querySelector("p");

            if (columnNumber) {
                if (stroke) {
                    // Adjust stroke width based on screen size
                    if (window.innerWidth < 767) {
                        stroke.style.width = "30px";
                    } else {
                        stroke.style.width = "42px";
                    }
                }

                // Adjust card-content styles
                cardContent.style.gap = "0";
                cardParagraph.style.paddingTop = "0.5rem";
            }
        });
    }

    // Run on page load
    adjustCardStyles();

    // Reapply styles on window resize
    window.addEventListener("resize", adjustCardStyles);
});

function initMap($el) {
    // Find marker elements within the map.
    var $markers = $el.find('.marker');

    // Create generic map using DEMO_MAP_ID for advanced markers.
    var mapArgs = {
        zoom: $el.data('zoom') || 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapId: '15ab2932d3239010', // Use demo map ID for advanced markers
        mapTypeControl: false,
    };
    var map = new google.maps.Map($el[0], mapArgs);

    // Add markers to the map.
    map.markers = [];
    $markers.each(function () {
        initAdvancedMarker($(this), map);
    });

    // Center the map based on markers.
    centerMap(map);

    // Return map instance.
    return map;
}

// Google API
function initAdvancedMarker($marker, map) {
    var lat = $marker.data('lat');
    var lng = $marker.data('lng');
    var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };

    // Create a customized PinElement
    const pinElement = new google.maps.marker.PinElement({
        background: "#CC5616",
        glyphColor: "white",
        borderColor: "#234291",
    });

    // Create an AdvancedMarkerElement using the PinElement
    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: latLng,
        map: map,
        content: pinElement.element, // Use the customized PinElement
    });

    // Add marker to map markers array for reference
    map.markers.push(marker);

    // If the marker contains HTML, add it to an infoWindow (optional)
    if ($marker.html()) {
        const infowindow = new google.maps.InfoWindow({
            content: $marker.html(),
        });

        // Add a click listener for opening the infoWindow
        marker.addListener('click', () => {
            infowindow.open(map, marker);
        });
    }
}

function centerMap(map) {
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach((marker) => {
        bounds.extend({
            lat: marker.position.lat,
            lng: marker.position.lng,
        });
    });

    if (map.markers.length === 1) {
        map.setCenter(bounds.getCenter());
    } else {
        map.fitBounds(bounds);
    }
}

// Render maps on page load.
$(document).ready(function () {
    $('.acf-map').each(function () {
        initMap($(this));
    });
});

jQuery(document).ready(function($) {
    var offset = 0;
    var categorySlug = '';
    var newsItemsContainer = $('#news-items');
    var loadMoreBtn = $('<div class="load-button-container"><a class="button" id="load-more-cases"><i class="fas fa-slash"></i>Laad meer</a></div>');
    var loadingIndicator = $('<div style="display: flex; justify-content: center; align-items: center; grid-column: 1 / -1;" class="loading-indicator">Laden...</div>');
    var totalPosts = 0;
    var postsPerPage = 6;

    // Initial Load
    loadCases(categorySlug, offset);

    // Event listener for the filter links
    $('.filter a').on('click', function(e) {
        e.preventDefault();

        offset = 0;
        categorySlug = $(this).data('category');

        $('.filter a').removeClass('active');
        $(this).addClass('active');

        loadCases(categorySlug, offset);
    });

    // Event handler for "Load More" button
    $(document).on('click', '#load-more-cases', function() {
        loadCases(categorySlug, offset);
    });

    function loadCases(category, currentOffset) {
        if (currentOffset === 0) {
            newsItemsContainer.empty(); // Clear container on initial load or new filter
        }

        newsItemsContainer.append(loadingIndicator);  // Show loading indicator
        $('#load-more-cases').remove(); // Remove existing "Load More" button

        $.ajax({
            type: 'POST',
            url: cases_filter_data.ajaxurl,
            data: {
                action: 'load_cases_by_category',
                category: category,
                offset: currentOffset,
                security: cases_filter_data.security
            },
            dataType: 'json',
            success: function(response) {
                if (currentOffset === 0) {
                    totalPosts = response.total_posts; // Get total posts on initial load of each filter
                }

                // Generate HTML from the 'nieuws' data:
                var html = '';
                response.nieuws.forEach(function(nieuwsData) { // Changed from 'cases' to 'nieuws'
                    html += '<div class="news-item card">';
                    if (nieuwsData.thumbnail) {
                        html += '<img src="' + nieuwsData.thumbnail + '" alt="' + nieuwsData.title + '">';
                    }
                    html += '<div class="content">';
                    html += '<h3>' + nieuwsData.title + '</h3>';
                    html += '<div class="stroke"></div>';
                    html += '<p>' + nieuwsData.excerpt + '</p>';
                    html += '</div>';
                    html += '<div class="readmore">';
                    html += '<a href="' + nieuwsData.permalink + '"><i class="fas fa-slash"></i>Lees meer</a>';
                    html += '</div>';
                    html += '</div>';
                });

                newsItemsContainer.append(html); // Append generated HTML
                offset = response.offset;       // Update offset

                if (response.more_posts || (offset < totalPosts && currentOffset !== 0)) {
                    // Use find() for more efficient selection:
                    let loadMoreButton = $('.load-button-container').find('#load-more-cases');

                    if (loadMoreButton.length === 0) {
                        loadMoreButton = $('<a class="button" id="load-more-cases"><i class="fas fa-slash"></i>Laad meer</a>');
                        $('.load-button-container').append(loadMoreButton);
                    }
                } else {
                    $('.load-button-container').empty();
                }
            },
            error: function(xhr, status, error) {
                console.error('AJAX error:', status, error);

                // Move error styles to CSS if possible, or keep inline if absolutely necessary:
                let errorIndicator = $('<div class="loading-indicator error">An error occurred. Please try again.</div>');
                newsItemsContainer.append(errorIndicator);
            },
            complete: function() {
                $('.loading-indicator', newsItemsContainer).remove();  // Hide loading indicator
            }
        });
    }
});